/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

export const Head = () => (
  <>
    <title>Nasze realizacje geodezyjne w Olsztynie | Pomiary i inwentaryzacje</title>
    <meta
      name="description"
      content="Zapoznaj się z naszymi zrealizowanymi projektami geodezyjnymi w Olsztynie i na terenie województwa warmińsko - mazurskiego. Oferujemy kompleksowe usługi geodezyjne, w tym pomiary terenowe i inwentaryzacje geodezyjne, także dla sektora lotniskowego."
    />
    <link rel="canonical" href="https://geo-partner.pl/realizacje/" />
  </>
);

const Main = styled.main`
  /* font-family: ${({ theme }) => theme.font.family.sans}; */

  table {
    border: 1px solid #e2e2e2;
    border-left: 0;
    border-bottom: 0;
    border-spacing: 0;
    font-size: 14px;
    margin: 0 -15px 50px;
    width: calc(100% + 30px);

    @media only screen and (min-width: 480px) {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }

    @media only screen and (min-width: 640px) { 
      font-size: 16px;
    }

    tr {
      &:nth-child(even) {
        td {
          background: #f4f4f4;
        }
      }

      &:hover {
        td {
          background: hsl(347,73%,95%);
        }
      }
    }

    td {
      transition: background 150ms ease-in;
      border-left: 1px solid #e2e2e2;
      border-bottom: 1px solid #e2e2e2;
      line-height: 130%;
      padding: 10px;

      &:last-child {
        width: 100px;
        text-align: right;

        @media only screen and (min-width: 640px) {
          padding: 10px 15px;
          width: 185px;
        }
      }
    }
  }
`;

const Projects = styled.section`
  .list-projects {
    list-style: none;
    margin: 0 auto 5rem;
    max-width: 840px;
    padding: 0;

    ul {
      list-style: square;
      margin: 0 0 4rem;

      ul {
        list-style: circle;
        margin: 1rem 0 1.5rem;
      }
    }

    > li {
      padding: 15px 15px 15px 25px;
      position: relative;

      &:last-child {
        > ul {
          margin: 0;
        }
      }

      &.last {
        &::after {
          display: none;
        }
      }

      &::after {
        background: ${({ theme }) => theme.secondary};
        content: '';
        left: 0;
        height: 100%;
        position: absolute;
        top: 0;
        width: 1px;
      }

      &::before {
        background: ${({ theme }) => theme.secondary};
        border-radius: 100%;
        content: '';
        height: 13px;
        left: -6px;
        position: absolute;
        top: 0;
        width: 13px;
      }

      h3 {
        margin: -21px 0 2rem;
      }

      @media only screen and (min-width: 960px) {
        padding: 0 15px 15px 160px;
        
        &::after {
          left: 136px;
        }

        &::before {
          left: 130px;
        }

        ul {
          position: relative;
          top: -7px;
        }

        h3 {
          left: 0;
          margin: 0;
          position: absolute;
          top: -9px;
          position: absolute;
        }
      }
    }
  }
`;

const Slider = styled.div`
  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`;

const IndexPage = ({ data }) => (
  <>
    <Slider>
      <GatsbyImage
        image={getImage(data.hero_2)}
        alt="Geodeta Olsztyn"
        loading="eager"
      />
    </Slider>

    <Main>
      <div className="row">

        <Projects>
          <h1>Nasze realizacje</h1>

          <ul className="list-projects">
            <li>
              <h3>2024-2021</h3>
              <ul>
                <li>
                  kompleksowa obsługa geodezyjna:
                  <ul>
                    <li><strong>Portu Lotniczego Olsztyn - Mazury</strong></li>
                    <li>lądowiska dla helikopterów w <strong>Szpitalu MSWiA</strong> w Warszawie</li>
                    <li>budowy obwodnicy Smolajn w ciągu DK51</li>
                    <li>rozbudowy drogi DW527 na odcinku Łukta - Olsztyn</li>
                  </ul>
                </li>
                <li>
                  nadzór geodezyjny nad budową:
                  <ul>
                    <li><strong>linii tramwajowej w Olsztynie</strong></li>
                    <li>
                      <strong>drogi S16 Olsztyn - Ełk</strong>
                      , odcinek Borki Wielkie - Mrągowo
                    </li>
                    <li>linii kolejowej nr 221 na odcinku Gutkowo - Dobre Miasto</li>
                  </ul>
                </li>
              </ul>
            </li>

            <li>
              <h3>2020-2015</h3>
              <ul>
                <li>
                  kompleksowa obsługa geodezyjna:
                  <ul>
                    <li><strong>Portu Lotniczego Olsztyn - Mazury</strong></li>
                    <li>budowy ulicy Pstrowskiego w Olsztynie z połączeniem do obwodnicy Olsztyna</li>
                    <li>budowy ulic Leonharda i Towarowej w Olsztynie z połaczeniem do obwodnicy Olsztyna</li>
                    <li>rozbudowy drogi wojewódzkiej DW504 na odcinku Frombork - Braniewo</li>
                  </ul>
                </li>
                <li>
                  nadzór geodezyjny nad budową:
                  <ul>
                    <li><strong>obwodnicy Olsztyna</strong></li>
                    <li>drogi wojewódzkiej DW512 na odcinku Pieniężno - Bartoszyce</li>
                  </ul>
                </li>
              </ul>
            </li>

            <li>
              <h3>2014-2012</h3>
              <ul>
                <li>
                  kompleksowa obsługa geodezyjna budowy:
                  <ul>
                    <li>linii kolejowej E65 - odcinek Redaki - granica LCS Iława</li>
                    <li>drogi S8 - odcinek Piotrków Trybunalski - Rawa Mazowiecka</li>
                  </ul>
                </li>
                <li>
                  nadzór geodezyjny nad budową:
                  <ul>
                    <li>obwodnicy Olecka (7,6km)</li>
                    <li>drogi wojewódzkiej Srokowo - Gołdap (77,5km)</li>
                    <li>linii tramwajowej w Olsztynie</li>
                  </ul>
                </li>
                <li>wznowienie granic w obrębie Jastrzębie Zdrój</li>
                <li>mapy do celów projektowych internetu szerokopasmowego dla Mazowsza (140km)</li>
                <li>ustalenie linii brzegowych strug, rzek i jezior (14km)</li>
              </ul>
            </li>

            <li>
              <h3>2011-2010</h3>
              <ul>
                <li>kompleksowa obsługa geodezyjna budowy obiektów inżynierskich w ciągu autostrady A1 - odcinek Nowe Marzy - Czerniewice (10 obiektów)</li>
                <li>nadzór geodezyjny nad budową drogi S7 - odcinek Olsztynek - Nidzica z obwodnicą Olsztynka</li>
              </ul>
            </li>

            <li>
              <h3>2009-2008</h3>
              <ul>
                <li>zlecenia dla WZMiUW w Olsztynie - ustalenie linii brzegowych rzek</li>
              </ul>
            </li>

            <li>
              <h3>2007-2006</h3>
              <ul>
                <li>mapy do celów projektowych światłowodu Olsztyn, Braniewo - Elbląg</li>
                <li>kompleksowa obsługa geodezyjna budowy obiektów inżynierskich w ciągu autostrady A1 - odcinek Swarożyn - Pelplin (6 obiektów w tym 2 o długości 250m metodą nasuwową)</li>
              </ul>
            </li>

            <li>
              <h3>2005</h3>
              <ul>
                <li>kompleksowa obsługa geodezyjna budowy obiektów inżynierskich w ciągu autostrady A2 - odcinek Emilia - Stryków - (20 obiektów)</li>
              </ul>
            </li>

            <li>
              <h3>2004</h3>
              <ul>
                <li>zlecenia dla ARiMR - pomiary kontrolne powierzchni klasoużytków na terenie powiatu olsztyńskiego</li>
              </ul>
            </li>

            <li>
              <h3>2003-2001</h3>
              <ul>
                <li>
                  budowa 3 zbiorników na ropę o pojemności 75&nbsp;000 m<sup style={{ fontSize: '1.2rem' }}>3</sup> każdy w miejscowości Mozyr - Białoruś
                </li>
                <li>obsługa geodezyjna gminy Dobre Miasto</li>
              </ul>
            </li>

            <li className="last">
              <h3>2001-1998</h3>
              <ul>
                <li>mapy do celów projektowych sieci telekomunikacyjnych (350km)</li>
                <li>inwentaryzacja sieci telekomunikacyjnych</li>
                <li>zlecenia dla AWRSP - wznowienia granic, podziały nieruchomości</li>
                <li>zlecenia dla WPB Olsztyn - budowa kamienicy przy ulicy Warmińskiej 14-16</li>
              </ul>
            </li>
          </ul>
        </Projects>

      </div>
    </Main>
  </>
);

export const query = graphql`
  query {
    hero_2: file(relativePath: {regex: "/hero\/2.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    }
  }
`;

export default IndexPage;
